<template>
  <div>
    <page-navigation
      @apply="apply"
      @setPageYOffset="setPageYOffset"
    ></page-navigation>
    <page-home ref="home" :pageYOffset="pageYOffset"></page-home>
  </div>
</template>

<script>
import navigation from "@/components/navigation";
// import footer from "@/components/footer";
import Home from "@/views/home";
export default {
  name: "Index",
  components: {
    pageNavigation: navigation,
    // pageFooter: footer,
    pageHome: Home,
  },
  data() {
    return {
      pageYOffset: 0
    };
  },
  methods: {
    apply() {
      this.$refs.home.apply();
    },
    setPageYOffset(value) {
      this.pageYOffset = value
    }
  },
};
</script>

<style></style>
