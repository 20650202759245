<template>
  <div class="page-home" :class="isMobile ? 'ismobile' : 'ispc'">
    <div class="iframe_wrapper" v-if="iframetoggle">
      <span
        class="iconfont icon-shanchu"
        v-if="showclose"
        @click="(iframetoggle = false), (showclose = false)"
      ></span>
      <iframe
        ref="iframe"
        src="https://chat.800ts.com.cn/ChatWeb/WebPages/CM/ChatCustomer.html"
        v-if="!isMobile"
        id="iframe"
        style="width: 602px; height: 542px; border: none"
      ></iframe>
    </div>
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      indicators
      :interval="3000"
      img-width="1024"
      img-height="480"
      v-show="!isMobile"
    >
      <b-carousel-slide v-if="!isMobile">
        <template #img>
          <div style="position: relative" class="banner_btn_wrapper">
            <img src="~@/assets/images/banner.png" alt="" style="width: 100%" />
            <span class="btn" @click="details">活动详情</span>
          </div>
        </template>
      </b-carousel-slide>
      <b-carousel-slide v-if="!isMobile">
        <template #img>
          <div class="top-section">
            <img
              class="d-none d-md-block bg"
              src="~@/assets/images/topbg@2x.png"
            />
            <img
              class="d-block d-md-none bg"
              src="~@/assets/images/topbg-mobile@2x.png"
            />
            <div class="section-container section1-container">
              <b-container>
                <b-row>
                  <b-col cols="12" md="6" xl="5">
                    <div class="section1-text">
                      <img
                        class="tm-text-logo d-block d-sm-none"
                        :class="{ show: showPage1 }"
                        src="~@/assets/images/tm@2x.png"
                        alt=""
                        srcset=""
                      />
                      <img
                        class="title"
                        :class="{ show: showPage1 }"
                        src="~@/assets/images/text.svg"
                        alt=""
                        srcset=""
                      />
                      <p :class="{ show: showPage1 }">
                        提供高仿真的场景式智能人机对练，如同一位专业的贴身教练随时随地陪同在所有学员身边，提高员工业务能力，助力企业实现运营的高效与业务的增长，适用于催收、营销、客服等以沟通为核心的业务场景中。
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" xl="7">
                    <b-embed
                      type="video"
                      ref="video"
                      aspect="16by9"
                      src="https://master-1305836573.cos.ap-guangzhou.myqcloud.com/assets/audios/tm_website.mov"
                      controlsList="nodownload"
                      allowfullscreen
                      controls
                      class="video-container"
                      :poster="poster"
                    ></b-embed>
                    <div
                      class="btn-play-video"
                      v-if="!isPlayVideo && !isMobile"
                      @click="playVideo"
                    >
                      <img
                        src="~@/assets/images/play@2x.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row class="d-flex d-md-none statistics-mobile">
                  <b-col cols="6" class="border-right border-bottom">
                    <div class="statistics mb-3">
                      <div class="value">
                        <span class="number din_score">
                          <digit-roll
                            v-model="digits[0]"
                            :duration="1000"
                            :delay="0"
                          ></digit-roll> </span
                        ><span class="plus">+</span>人
                      </div>
                      <div class="title">服务用户</div>
                    </div>
                  </b-col>
                  <b-col cols="6" class="border-bottom">
                    <div class="statistics mb-3">
                      <div class="value">
                        <span class="number din_score">
                          <digit-roll
                            v-model="digits[1]"
                            :duration="1000"
                            :delay="0"
                          ></digit-roll> </span
                        ><span class="plus">+</span>分钟
                      </div>
                      <div class="title">培训时长</div>
                    </div>
                  </b-col>
                  <b-col cols="6" class="border-right">
                    <div class="statistics mt-3">
                      <div class="value">
                        <span class="number din_score">
                          <digit-roll
                            v-model="digits[2]"
                            :duration="1000"
                            :delay="0"
                          ></digit-roll> </span
                        ><span class="plus">+</span>次
                      </div>
                      <div class="title">对练次数</div>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="statistics mt-3">
                      <div class="value">
                        <span class="number din_score">
                          <digit-roll
                            v-model="digits[3]"
                            :duration="1000"
                            :delay="0"
                          ></digit-roll> </span
                        ><span class="plus">+</span>句
                      </div>
                      <div class="title">行业话术</div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="mobile_wrapper" v-if="isMobile">
      <div class="img_wrapper">
        <img
          src="~@/assets/images/banner_mobile.png"
          style="width: 100%"
          alt=""
        />
        <span class="btn" @click="details">活动详情</span>
      </div>
      <div class="top-section">
        <img class="d-none d-md-block bg" src="~@/assets/images/topbg@2x.png" />
        <img
          class="d-block d-md-none bg"
          src="~@/assets/images/topbg-mobile@2x.png"
        />
        <div class="section-container section1-container">
          <b-container>
            <b-row>
              <b-col cols="12" md="6" xl="5">
                <div class="section1-text">
                  <img
                    class="tm-text-logo d-block d-sm-none"
                    :class="{ show: showPage1 }"
                    src="~@/assets/images/tm@2x.png"
                    alt=""
                    srcset=""
                  />
                  <img
                    class="title"
                    :class="{ show: showPage1 }"
                    src="~@/assets/images/text.svg"
                    alt=""
                    srcset=""
                  />
                  <p :class="{ show: showPage1 }">
                    提供高仿真的场景式智能人机对练，如同一位专业的贴身教练随时随地陪同在所有学员身边，提高员工业务能力，助力企业实现运营的高效与业务的增长，适用于催收、营销、客服等以沟通为核心的业务场景中。
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="6" xl="7">
                <b-embed
                  type="video"
                  ref="video"
                  aspect="16by9"
                  src="https://master-1305836573.cos.ap-guangzhou.myqcloud.com/assets/audios/tm_website.mov"
                  controlsList="nodownload"
                  allowfullscreen
                  controls
                  class="video-container"
                  :poster="poster"
                ></b-embed>
                <div
                  class="btn-play-video"
                  v-if="!isPlayVideo && !isMobile"
                  @click="playVideo"
                >
                  <img src="~@/assets/images/play@2x.png" alt="" srcset="" />
                </div>
              </b-col>
            </b-row>
            <b-row class="d-flex d-md-none statistics-mobile">
              <b-col cols="6" class="border-right border-bottom">
                <div class="statistics mb-3">
                  <div class="value">
                    <span class="number din_score">
                      <digit-roll
                        v-model="digits[0]"
                        :duration="1000"
                        :delay="0"
                      ></digit-roll> </span
                    ><span class="plus">+</span>人
                  </div>
                  <div class="title">服务用户</div>
                </div>
              </b-col>
              <b-col cols="6" class="border-bottom">
                <div class="statistics mb-3">
                  <div class="value">
                    <span class="number din_score">
                      <digit-roll
                        v-model="digits[1]"
                        :duration="1000"
                        :delay="0"
                      ></digit-roll> </span
                    ><span class="plus">+</span>分钟
                  </div>
                  <div class="title">培训时长</div>
                </div>
              </b-col>
              <b-col cols="6" class="border-right">
                <div class="statistics mt-3">
                  <div class="value">
                    <span class="number din_score">
                      <digit-roll
                        v-model="digits[2]"
                        :duration="1000"
                        :delay="0"
                      ></digit-roll> </span
                    ><span class="plus">+</span>次
                  </div>
                  <div class="title">对练次数</div>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="statistics mt-3">
                  <div class="value">
                    <span class="number din_score">
                      <digit-roll
                        v-model="digits[3]"
                        :duration="1000"
                        :delay="0"
                      ></digit-roll> </span
                    ><span class="plus">+</span>句
                  </div>
                  <div class="title">行业话术</div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <div class="section-container section1-1-container">
      <b-container>
        <b-row no-gutters class="d-none d-md-flex">
          <b-col>
            <div class="statistics border-right">
              <div class="value">
                <span class="number din_score">
                  <digit-roll
                    v-model="digits[0]"
                    :duration="1000"
                    :delay="0"
                  ></digit-roll> </span
                ><span class="plus">+</span>人
              </div>
              <div class="title">服务用户</div>
            </div>
          </b-col>
          <b-col>
            <div class="statistics border-right">
              <div class="value">
                <span class="number din_score">
                  <digit-roll
                    v-model="digits[1]"
                    :duration="1000"
                    :delay="0"
                  ></digit-roll> </span
                ><span class="plus">+</span>分钟
              </div>
              <div class="title">培训时长</div>
            </div>
          </b-col>
          <b-col>
            <div class="statistics border-right">
              <div class="value">
                <span class="number din_score">
                  <digit-roll
                    v-model="digits[2]"
                    :duration="1000"
                    :delay="0"
                  ></digit-roll> </span
                ><span class="plus">+</span>次
              </div>
              <div class="title">对练次数</div>
            </div>
          </b-col>
          <b-col>
            <div class="statistics">
              <div class="value">
                <span class="number din_score">
                  <digit-roll
                    v-model="digits[3]"
                    :duration="1000"
                    :delay="0"
                  ></digit-roll> </span
                ><span class="plus">+</span>句
              </div>
              <div class="title">行业话术</div>
            </div>
          </b-col>
        </b-row>
        <b-row class="section1-row2">
          <b-col cols="12">
            <div class="section1-text1-container">
              <!-- <div class="arrow-flex">
                  <img src="~@/assets/images/arrow@2x.png" alt="" srcset="" />
                  <img src="~@/assets/images/arrow@2x.png" alt="" srcset="" />
                </div> -->
              <div class="section1-text1">传统培训<span>痛点</span></div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="section1-advantage">
              <b-container fluid="md">
                <b-row>
                  <b-col>
                    <div class="item">
                      <span class="icomoon icon-refresh"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span class="d-none d-md-block span"
                        >业务<br />更新快</span
                      >
                      <span class="d-block d-md-none span">业务更新快</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="item">
                      <span class="icomoon icon-rmb2"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span class="d-none d-md-block span"
                        >培训<br />成本高</span
                      >
                      <span class="d-block d-md-none span">培训成本高</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="item">
                      <span class="icomoon icon-book2"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span class="d-none d-md-block span"
                        >培训<br />课程多</span
                      >
                      <span class="d-block d-md-none span">培训课程多</span>
                    </div>
                  </b-col>
                  <div class="w-100 d-block d-md-none"></div>
                  <b-col>
                    <div class="item">
                      <span class="icomoon icon-question"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span class="d-none d-md-block span"
                        >培训成效<br />难统计</span
                      >
                      <span class="d-block d-md-none span">培训成效难统计</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="item">
                      <span class="icomoon icon-idcard"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span class="d-none d-md-block span"
                        >学员成长<br />周期长</span
                      >
                      <span class="d-block d-md-none span">学员成长周期长</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="item">
                      <span class="icomoon icon-message"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span class="d-none d-md-block span"
                        >真实场景<br />开口难</span
                      >
                      <span class="d-block d-md-none span">真实场景开口难</span>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 统计分析  -->
    <div class="section2-container">
      <b-container>
        <b-row>
          <b-col>
            <div class="section2-text text-left">
              <h2>一线员工的智能贴身教练</h2>
            </div>
          </b-col>
        </b-row>
        <b-row align-v="center" class="section2-row">
          <b-col cols="12" lg="6">
            <div class="section2-text text-left">
              <div class="section2-item">
                <h3>沉浸式人机对练，强化学习效果</h3>
                <p>
                  结合语音识别、情感识别、自然语言处理、声音复刻等 AI
                  技术，模拟真实的客户与学员进行场景式训练，帮助学员快速高效掌握话术规范与技巧。
                </p>
                <ul>
                  <li class="d-flex align-items-center">
                    <span class="dot"></span>多学习方式个性化任务，智能推送
                  </li>
                  <li class="d-flex align-items-center">
                    <span class="dot"></span>多平台接入，随时随地碎片化学习
                  </li>
                  <li class="d-flex align-items-center">
                    <span class="dot"></span>多维度智能评分体系，量化学员能力
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="section2-imgs">
              <img
                data-trigger
                data-slideInBottom
                class="section2-img1"
                src="~@/assets/images/section2-img1@2x.png"
                alt=""
                srcset=""
              />
            </div>
          </b-col>
        </b-row>
        <b-row align-v="center" class="row-reverse section2-row">
          <b-col cols="12" lg="6">
            <div class="section2-text text-left">
              <div class="section2-item">
                <h3>声纹实时检测，防代考代练</h3>
                <p>
                  培训过程和考试中，实时声纹检测，防止代练代考，确保培训效果，保证考试结果的公平性。
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="section2-imgs">
              <img
                data-trigger
                data-slideInBottom
                class="section2-img2"
                src="~@/assets/images/section2-img2@2x.png"
                alt=""
                srcset=""
              />
            </div>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col>
            <div class="section2-text text-left">
              <div class="section2-item">
                <h3>自定义全局规划，可视化管理</h3>
                <p>
                  管理者可以自定义课程、练习及考试内容，查看学员成绩数据，并支持一键导出。
                </p>
                <ul>
                  <li class="d-flex align-items-center">
                    <span class="dot"></span>轻松搭建人才培养考核体系
                  </li>
                  <li class="d-flex align-items-center">
                    <span class="dot"></span>金牌话术标准化批量复制
                  </li>
                  <li class="d-flex align-items-center">
                    <span class="dot"></span>丰富数据看板轻松掌握培训效果
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="section2-imgs">
              <img
                data-trigger
                data-slideInBottom
                class="section2-img3"
                src="~@/assets/images/section2-img3@2x.png"
                alt=""
                srcset=""
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 核心价值 -->
    <div class="section-container section3-container">
      <b-container>
        <b-row>
          <b-col>
            <div class="section3-text">
              <!-- <img
                class="tm-text-logo"
                src="~@/assets/images/tm@2x.png"
                alt=""
                srcset=""
              /> -->
              <h2 class="title">核心价值</h2>
            </div>
          </b-col>
        </b-row>
        <b-row class="section3-values">
          <b-col cols="4" md="2">
            <div class="section3-item">
              <span class="icomoon icon-grow"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
              <div class="section3-text1">提升学员<br />培训效率</div>
            </div>
          </b-col>
          <b-col cols="4" md="2">
            <div class="section3-item">
              <span class="icomoon icon-chart"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
              <div class="section3-text1">量化学员<br />培训效果</div>
            </div>
          </b-col>
          <b-col cols="4" md="2">
            <div class="section3-item">
              <span class="icomoon icon-waitting"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span
              ></span>
              <div class="section3-text1">缩短学员<br />成长周期</div>
            </div>
          </b-col>
          <b-col cols="4" md="2">
            <div class="section3-item">
              <span class="icomoon icon-rmb"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
              <div class="section3-text1">减少企业<br />培训成本</div>
            </div>
          </b-col>
          <b-col cols="4" md="2">
            <div class="section3-item">
              <span class="icomoon icon-persons"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
              <div class="section3-text1">企业标准化<br />复制人才</div>
            </div>
          </b-col>
          <b-col cols="4" md="2">
            <div class="section3-item">
              <span class="icomoon icon-target"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
              ></span>
              <div class="section3-text1">提高<br />业务准确率</div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 核心优势  -->
    <div class="section-container section2-container section4-container">
      <b-container>
        <b-row>
          <b-col md="5">
            <div class="section4-imgs">
              <div class="section4-img">
                <img
                  class="section4-img1 d-block"
                  data-trigger
                  data-slideInLeft
                  src="~@/assets/images/adv-img1@2x.png"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="section4-img">
                <img
                  class="section4-img2 d-md-block d-none"
                  data-trigger
                  data-slideInLeft
                  src="~@/assets/images/adv-img2@2x.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="section2-text section4-text text-left">
              <!-- <img
                class="tm-text-logo"
                src="~@/assets/images/tm@2x.png"
                alt=""
                srcset=""
              /> -->
              <h2>核心优势</h2>
              <div class="section2-item">
                <h3><span class="icomoon icon-voice"></span>核心技术领先</h3>
                <p>
                  领先的AI技术，构建高仿真的业务场景，沉浸式人机对练，多维度智能评分体系，让AI赋能企业以“智”提“质”。
                </p>
              </div>
              <div class="section2-item">
                <h3><span class="icomoon icon-voice"></span>顾问式专业服务</h3>
                <p>
                  结合25+年的培训和运营经验，组建专家团队，将理论知识转化为实际应用场景对练话术，提供持续性优化的顾问式服务。
                </p>
              </div>
              <div class="section2-item">
                <h3><span class="icomoon icon-voice"></span>产品使用便捷</h3>
                <p>
                  多平台接入，随时随地碎片化学习；可视化成绩看板，轻松管理。
                </p>
              </div>
            </div>
            <div class="section4-imgs d-block d-md-none">
              <div class="section4-img section4-img2">
                <img
                  class="d-block"
                  data-trigger
                  data-slideInLeft
                  src="~@/assets/images/adv-img2@2x.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 行业应用 -->
    <div class="section-container section5-container">
      <b-container>
        <b-row>
          <b-col>
            <div class="section5-item">
              <div class="section5-header section5-header2">
                <span>行业应用</span>
              </div>
              <b-container fluid class="industry-items">
                <b-row cols="4" cols-md="5">
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong1"></i>
                      <span>金融行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong2"></i>
                      <span>非银机构</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong3"></i>
                      <span>呼叫中心</span>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong6"></i>
                      <span>餐饮行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong7"></i>
                      <span>教育行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong8"></i>
                      <span>快消行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong9"></i>
                      <span>支付行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong10"></i>
                      <span>医美行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong5"></i>
                      <span>汽车行业</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">
                      <i class="font_family icon-hangyeyingyong4"></i>
                      <span>汽车出行</span>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <div class="section5-item section5-item2">
              <div class="section5-header">
                <span>应用场景</span>
              </div>
              <b-container fluid class="scen-container">
                <b-row cols="4" cols-md="5">
                  <b-col>
                    <div class="industry-item">电话催收</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">理财推广</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">会员推广</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">保险营销</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">投资顾问</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">门店销售</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">客户服务</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">投诉处理</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">客户回访</div>
                  </b-col>
                  <b-col>
                    <div class="industry-item">更多......</div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 15天试用  -->
    <div
      class="section2-container section7-container"
      :style="{ overflow: lgScreenTransitionEnd ? 'hidden' : 'visiable' }"
    >
      <b-container>
        <b-row>
          <b-col :class="isMobile ? '' : 'pccol'">
            <div class="section7-text text-left">
              <div class="free-container">
                <div class="title1">
                  <i class="font_family iconfont icon-a-30"></i><span>天</span>
                </div>
                <div class="free">免费试用</div>
              </div>
              <div class="use-text">
                0<span>&nbsp;成本体验&nbsp;</span
                ><br class="d-none d-md-block" />TrainingMaster
                <span>带来的改变</span>
              </div>
              <!-- <div class="btn-apply" v-b-modal.modal-prevent-closing>
                申请体验
              </div> -->
            </div>
          </b-col>
          <b-col>
            <div class="section7-imgs">
              <img
                class="section7-img1"
                data-trigger
                data-slideInRight
                src="~@/assets/images/pc@2x.png"
                alt=""
                srcset=""
                ref="lgScreen"
              />
              <img
                class="section7-img2"
                data-trigger
                data-slideInLeft
                src="~@/assets/images/phone@2x.png"
                alt=""
                srcset=""
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      scrollable
      @show="resetModal"
      @hidden="resetModal"
      modal-class="modal-form"
      size="sm"
      centered
      hide-footer
    >
      <template #modal-title>
        <div class="submit-head">
          <img
            class="form-head"
            src="~@/assets/images/formHead@2x.png"
            alt=""
            srcset=""
          />
        </div>
      </template>
      <template #modal-header-close>
        <span class="font_family icon-guanbi"></span>
      </template>
      <apply-form></apply-form>
    </b-modal>
    <!-- 合作伙伴 -->
    <div class="section-container section3-container section8-container">
      <b-container fluid>
        <b-row no-gutters>
          <b-col>
            <div class="section3-text section8-text">
              <h2 class="title">合作伙伴</h2>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters class="d-none d-md-flex">
          <b-col @mouseleave="startScrolling" @mouseenter="stopScrolling">
            <swiper class="swiper" :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(imgs, index) in customers" :key="index">
                <div>
                  <b-img
                    class="customer-img"
                    :src="img.src"
                    :blank="img.src ? false : true"
                    alt=""
                    fluid
                    height="80"
                    width="212"
                    srcset=""
                    blank-color="#fff"
                    v-for="(img, i) in imgs"
                    :key="index + '_' + i"
                  />
                </div>
              </swiper-slide>
            </swiper>
          </b-col>
        </b-row>
        <b-row no-gutters class="d-flex d-md-none customers-row">
          <b-col v-for="(imgs, index) in customers" :key="index" cols="4">
            <div>
              <b-img
                class="customer-img"
                :src="img.src"
                :blank="img.src ? false : true"
                alt=""
                fluid
                height="48"
                width="100"
                srcset=""
                blank-color="#fff"
                v-for="(img, i) in imgs"
                :key="index + '_' + i"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 联系我们  -->
    <div class="section-container section2-container section9-container">
      <b-container>
        <b-row>
          <b-col md="6">
            <div class="section2-text section4-text contact-text text-left">
              <h2>联系我们</h2>
              <div id="dituContent" class="d-block d-md-none"></div>
              <div class="contact-btns">
                <div
                  class="btn-contact btn-outline"
                  :class="{ active: addressType === 0 }"
                  @click="changeMap(0)"
                >
                  深圳
                </div>
                <div
                  class="btn-contact border-left-0"
                  :class="{ active: addressType === 1 }"
                  @click="changeMap(1)"
                >
                  上海
                </div>
                <div
                  class="btn-contact border-left-0"
                  :class="{ active: addressType === 2 }"
                  @click="changeMap(2)"
                >
                  广州
                </div>
                <div
                  class="btn-contact border-left-0"
                  :class="{ active: addressType === 3 }"
                  @click="changeMap(3)"
                >
                  重庆
                </div>
              </div>
              <div class="contact-infos">
                <div class="contact-item">
                  <i class="font_family icon-dizhi"></i>
                  深圳市南山区粤兴二道10号香港中文大学深圳研究院8楼
                </div>
                <div class="contact-item">
                  <i class="font_family icon-dianhua"></i>
                  <span class="phone">4000-858-998</span>
                </div>
                <div class="contact-item contact2-item">
                  <i class="font_family icon-dizhi"></i>
                  上海市浦东新区峨山路91弄120号8号楼五楼
                </div>
                <div class="contact-item">
                  <i class="font_family icon-dianhua"></i>
                  <span class="phone">021-5820 2288</span>
                </div>
                <div class="contact-item contact2-item">
                  <i class="font_family icon-dizhi"></i>
                  广州市越秀区中山五路33号大马站商业中心14楼
                </div>
                <div class="contact-item">
                  <i class="font_family icon-dianhua"></i>
                  <span class="phone">020-3160 2288</span>
                </div>
                <div class="contact-item contact2-item">
                  <i class="font_family icon-dizhi"></i>
                  重庆市渝北区食品城大道18号重庆广告产业园F3栋5-2
                </div>
                <div class="contact-item">
                  <i class="font_family icon-dianhua"></i>
                  <span class="phone">023-8620 2288</span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="6" class="d-none d-md-block">
            <div id="dituContent2" class="d-none d-md-block"></div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 底部版权 -->
    <div class="page-footer">
      <div class="page-footer-container">
        <img src="~@/assets/images/qr@4x.png?v=1222" alt="" srcset="" />
        <h1>智能数字教练</h1>
        <p class="copyright">
          Copyright©2021 VoiceAI Technologies Co., Ltd. ALL rights reserved.<br />
          <a href="http://beian.miit.gov.cn/" target="_blank"
            >粤ICP备18049324号</a
          >
        </p>
      </div>
    </div>
    <div class="fixed-btns" :style="{ right: btnRight }">
      <div class="wx-message">
        <div class="btn btn-message" @click.stop="openChatmaster">
          <!-- <span class="icomoon icon-wx"></span> -->
          <span class="iconfont icon-zaixianzixun1"></span>
          <div>在线联系</div>
        </div>
        <div class="wx-qrcode" v-show="showWxCode">
          <img src="~@/assets/images/contact-wx@2x.png" alt="" />
          <div>手机扫码添加售前专家</div>
        </div>
      </div>
      <div
        class="btn btn-top"
        :class="{ show: showBackTop }"
        @click="backToTop"
      >
        <span class="icomoon icon-backtop"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import ScrollTrigger from "@terwanerik/scrolltrigger";
import utils from "@/utils";
import { chunk } from "lodash";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import applyForm from "@/components/applyForm";

let timer = null;
let customers = [
  {
    src: require("@/assets/images/p-logo1@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo2@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo3@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo4@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo5@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo6@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo7@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo8@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo9@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo10@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo11@2x.png"),
  },
  {
    src: require("@/assets/images/p-logo12@2x.png"),
  },
];
let mobile_customers = [
  {
    src: require("@/assets/images/mobile/logo1@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo2@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo3@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo4@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo5@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo6@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo7@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo8@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo9@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo10@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo11@2x.png"),
  },
  {
    src: require("@/assets/images/mobile/logo12@2x.png"),
  },
];
if (utils.isMobile()) {
  customers = chunk(mobile_customers, 2);
} else {
  customers = chunk(customers, 2);
}

// https://github.com/nolimits4web/swiper/issues/4730
if (customers.length < Math.floor(window.innerWidth / 236)) {
  customers = [...customers, ...customers];
}
var map = {}; // 存储百度map实例
export default {
  components: {
    swiper,
    swiperSlide,
    applyForm,
  },
  mixins: [validationMixin],
  props: {
    pageYOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      name: "",
      submittedNames: [],
      poster: require("@/assets/images/video@2x.png"),
      btnRight: utils.isMobile() ? "24px" : "40px",
      isPlayVideo: false,
      isMobile: utils.isMobile(),
      lgScreenTransitionEnd: false,
      swiperOption: {
        autoplay: {
          delay: 1,
        },
        slidesPerView: Math.floor(window.innerWidth / 236),
        slidesPerGroup: 1,
        freeMode: true,
        allowTouchMove: false,
        speed: 5000,
        spaceBetween: 0,
        loop: true,
      },
      customers,
      addressType: 0, // 0 深圳地址 1 上海地址
      showWxCode: false, // 显示微信二维码
      showBackTop: false, // 显示返回顶部按钮
      showPage1: false,
      digits: [300000, 10000000, 3000000, 500000],
      iframetoggle: false,
      showclose: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {
    pageYOffset(v) {
      if (v > window.innerHeight) {
        this.showBackTop = true;
      } else {
        this.showBackTop = false;
      }
    },
  },
  mounted() {
    this.createMap("dituContent");
    this.createMap("dituContent2");
    setTimeout(() => {
      const trigger = new ScrollTrigger({
        trigger: {
          once: true,
        },
      });
      trigger.add("[data-trigger]");
      this.showPage1 = true;
    }, 300);
    if (!utils.isMobile()) {
      this.$root.$on("bv::modal::show", () => {
        this.btnRight = "55px";
      });
      this.$root.$on("bv::modal::hidden", () => {
        this.btnRight = "40px";
      });
    }

    // console.log();
    this.$video = this.$refs.video.querySelector("video");
    this.$video.addEventListener("play", (event) => {
      console.log(event);
      this.isPlayVideo = true;
    });
    this.$video.addEventListener("pause", (event) => {
      console.log(event);
      this.isPlayVideo = false;
    });
    this.$video.addEventListener("ended", (event) => {
      console.log(event);
      this.isPlayVideo = false;
    });
    this.$refs.lgScreen.addEventListener("transitionend", (res) => {
      // console.log(res);
      if (res.propertyName === "transform") {
        this.lgScreenTransitionEnd = true;
      }
    });

    document.body.addEventListener("click", (event) => {
      if (event.target.className.indexOf("btn-message") == -1) {
        this.showWxCode = false;
      }
    });
  },
  methods: {
    playVideo() {
      this.$video.play();
    },
    createMap(id) {
      map[id] = new window.BMapGL.Map(id); // 创建Map实例
      map[id].centerAndZoom(new window.BMapGL.Point(113.951363, 22.537122), 19); // 初始化地图,设置中心点坐标和地图级别
      map[id].enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      this.changeMap(0, map[id]);
    },

    changeMap(type, mapInstance) {
      if (!mapInstance) {
        mapInstance = this.isMobile ? map["dituContent"] : map["dituContent2"];
      }
      this.addressType = type;
      const address = [
        [113.951363, 22.537122],
        [121.539126, 31.223997],
        [113.274541, 23.131697],
        [106.615535, 29.654729],
      ];
      mapInstance.clearOverlays();
      // 创建地点图标
      var new_point = new window.BMapGL.Point(...address[type]);
      let width = utils.isMobile() ? 32 : 58;
      var myIcon = new window.BMapGL.Icon(
        require("../assets/images/icon-address.png"),
        new window.BMapGL.Size(width, width)
      );
      var pt = new window.BMapGL.Point(...address[type]);
      var marker = new window.BMapGL.Marker(pt, {
        icon: myIcon,
      });
      // var marker = new window.BMapGL.Marker(new_point); // 创建标注
      mapInstance.addOverlay(marker); // 将标注添加到地图中
      // mapInstance.panTo(new_point); //转到该点位置
      mapInstance.centerAndZoom(new_point, 18);
    },

    backToTop() {
      let vm = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        let oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        let ispeed = Math.floor(-oTop / 8);
        if (oTop > 0) {
          if (oTop > window.innerHeight) {
            vm.showBackTop = true;
          } else {
            vm.showBackTop = false;
          }
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop + ispeed;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
        }
      });
    },

    toggleWxCode(e) {
      if (e.type == "click") {
        this.showWxCode = true;
      } else {
        this.showWxCode = !this.showWxCode;
      }
    },
    resetModal() {
      // this.resetForm();
    },
    apply() {
      this.$bvModal.show("modal-prevent-closing");
    },

    startScrolling() {
      let x = parseInt(this.swiper.translate, 10);
      this.swiper.$el[0].querySelector(
        ".swiper-wrapper"
      ).style.transitionDuration = this.scrollingTime + "ms";
      this.swiper.$el[0].querySelector(
        ".swiper-wrapper"
      ).style.transform = `translate3d(${x}px, 0px, 0px)`;
      // this.swiper.autoplay.start()
    },

    stopScrolling() {
      this.scrollingTime =
        ((this.swiper.getTranslate() - this.swiper.translate) / 236) * 5000 ||
        20;
      let x = parseInt(this.swiper.getTranslate(), 10);
      this.swiper.$el[0].querySelector(
        ".swiper-wrapper"
      ).style.transitionDuration = "0ms";
      this.swiper.$el[0].querySelector(
        ".swiper-wrapper"
      ).style.transform = `matrix(1, 0, 0, 1, ${x}, 0)`;
      // this.swiper.autoplay.stop()
    },

    openChatmaster() {
      if (this.isMobile) {
        window.open(
          // "https://chat.800ts.com.cn/ChatWeb/WebPages/CM/ChatCustomer.html",
          "https://chat.800ts.com.cn/ChatWeb/WebPages/CM/AdaptiveChatCustomer.html",
          "_blank"
        );
      } else {
        this.iframetoggle = true;
        setTimeout(() => {
          this.showclose = true;
        }, 150);
      }
    },
    details() {
      this.$router.push("/activitydetails");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
@import "../assets/iconfont/iconfont.css";
.page-home {
  &.ismobile {
    #carousel-fade {
      display: none;
    }
  }
  &.ispc {
    .mobile_wrapper {
      display: none;
    }
  }
}
.mobile_wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .img_wrapper {
    position: relative;
    img {
      display: block;
      height: auto;
      width: 101% !important;
    }
  }
  .btn {
    position: absolute;
    background: #5769ff;
    height: 80px;
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 100;
    bottom: 5em;
    width: 100%;
    opacity: 0;
  }

  .top-section {
    margin-top: -2px;
  }
}
.banner_btn_wrapper {
  // height: 448px;
  img {
    object-fit: cover;
  }
  .btn {
    position: absolute;
    bottom: 0;
    background: #5769ff;
    height: 80px;
    display: flex;
    align-items: center;
    color: #fff;
    left: 35%;
    transform: translate(-50%, 0%);
    z-index: 100;
    bottom: 20%;
    padding: 0 200px;
    opacity: 0;
  }
}
.ispc .btn-message .iconfont {
  font-size: 30px;
  padding: 6px 0;
}
.ismobile .btn-message .iconfont {
  font-size: 24px;
  padding: 8px 0 0px;
}
.pccol {
  display: flex;
  align-items: center;
}
.iframe_wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  span {
    background: #000;
    cursor: pointer;
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -15px;
    top: -15px;
    border-radius: 50%;
  }
}
</style>
