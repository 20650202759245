<template>
  <div class="navbar-top" :class="{ 'fixed': pageYOffset > 40 }">
    <b-container>
      <b-navbar toggleable="lg" type="light" :variant="pageYOffset > 40 ? 'light' : 'faded'">
        <b-navbar-brand href="#">
          <img :src="pageYOffset > 40 ? logo : whiteLogo" class="align-top logo d-none d-sm-block"
            alt="TrainingMaster" />
          <img src="~@/assets/images/sm-logo@2x.png" class="align-top sm-logo d-block d-sm-none" alt="TrainingMaster" />
        </b-navbar-brand>

        <b-navbar-nav class="ml-auto right-btns">
          <b-button right class="btn-login" @click="login">
            <i class="font_family icon-denglu"></i>登录
          </b-button>
          <!-- <b-button variant="primary" right class="btn-login btn-apply" @click="doApply">
            申请免费试用
          </b-button> -->
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
import utils from "@/utils";
const logo = require("@/assets/images/logo@2x.png")
const whiteLogo = require("@/assets/images/logo-white@2x.png")

export default {
  data() {
    return {
      activeIndex: 0,
      pageYOffset: 0,
      logo,
      whiteLogo,
    };
  },
  watch: {
    pageYOffset(v) {
      this.$emit("setPageYOffset", v)
    }
  },
  mounted() {
    console.log(document.body.scrollTop);
    this.pageYOffset = window.pageYOffset;
    window.onscroll = () => {
      this.pageYOffset = window.pageYOffset;
    };
  },
  methods: {
    doApply() {
      if (!utils.isMobile()) {
        this.$emit("apply");
      } else {
        this.$router.push({
          name: 'SubmitPage'
        })
      }
    },
    login() {
      if (!utils.isMobile()) {
        window.open(process.env.VUE_APP_TM_ADMIN_URL, "_blank");
      } else {
        window.open(process.env.VUE_APP_LOGIN_URL, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@function px2rem($value, $ratio) {
  @return $value / $ratio +0rem;
}

.navbar-top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 10;
  display: flex;
  align-items: center;
  z-index: 99;

  &.fixed {
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(6, 6, 60, 0.06);

    .btn-login {
      background: rgba($color: #5769ff, $alpha: 0.1);
      border-color: transparent;
      color: #5769ff;
    }

    .btn-apply {
      background: #5769ff;
      color: #ffffff;
    }
  }

  @media screen and(max-width: 576px) {
    height: 50px;

    &.border-bottom {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
      border-bottom: 0 !important;
    }
  }
}

.bg-light {
  background: #fff !important;
}

.logo {
  width: 20.25rem;
}

.sm-logo {
  width: 2rem;
  height: 2rem;
}

.icon-me {
  display: inline-block;
  margin-top: 5px;
  margin-right: 5px;
  width: 16px;
}

.right-btns {
  flex-direction: row;
}

.btn-login {
  display: inline-block;
  min-width: 7.75rem;
  height: 40px;
  background: rgba($color: #5769ff, $alpha: 0);
  border-radius: 8px;
  border-color: rgba($color: #fff, $alpha: 0.5);
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .font_family {
    margin-right: 0.1875rem;
    font-size: 0.875rem;
  }

  @media screen and(max-width: 1920px) {
    padding: 0.65rem 1.2rem;
  }

  @media screen and(max-width: 576px) {
    padding: 2px 16px;
    font-size: 12px;
    min-width: 72px;
    height: 28px;
    border-radius: 0.2857rem;

    .font_family {
      font-size: px2rem(11, 12);
    }
  }

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: #6f57ff;
    color: #fff;
    border-color: transparent;
  }
}

.btn-apply {
  margin-left: 1rem;
  min-width: 7.75rem;
  height: 40px;
  background: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #5769FF;
  line-height: 1.375rem;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: #6f57ff;
    color: #fff;
    border-color: transparent;
  }

  @media screen and(max-width: 576px) {
    padding: 2px 16px;
    font-size: 12px;
    min-width: 72px;
    height: 28px;
    border-radius: 0.2857rem;

    .font_family {
      font-size: px2rem(11, 12);
    }
  }
}

.navbar {
  padding: 10px 0;

  @media screen and(min-width: 1920px) {
    // padding: 1.25rem 8.125rem 0.5rem;
  }

  @media screen and(max-width: 1440px) {
    // padding: 1.25rem 2.125rem 0.5rem;
  }

  @media screen and(max-width: 576px) {
    padding: 0.5rem 0;
  }
}
</style>
